<template>
  <div class="h-full">
    <div class="marketplace-dashboard">
      <div class="sheader">
        <h2 class="component-title">Marketplace</h2>
        <p>Encontre aqui toda nossa gama de produtos e serviços para seus leilões</p>
        <div class="search">
          <u-input v-model="search" :before="[{icon: 'search', handler () {}}]" placeholder="Buscar no marketplace" inverted />
        </div>
      </div>

      <div class="services" v-if="!loading">
        <div class="service" v-for="produto in filteredProdutos" :key="produto.id">
          <div class="service-image"></div>
          <div class="service-body">
            <div class="service-name">{{ produto.nome }}</div>
            <div class="service-desc">
              {{produto.descricaoSimples}}
              <div class="m-t" v-if="produto.criterioCobranca === types.CRITERIO_COBRANCA_UNICA">R$ {{produto.valor|moeda}}</div>
              <div class="m-t" v-if="produto.criterioCobranca === types.CRITERIO_COBRANCA_MENSAL">R$ {{produto.valor|moeda}} por mês</div>
            </div>
            <div @click="open(produto)" class="service-opts">
              <u-btn v-if="produto.criterioCobranca === types.CRITERIO_COBRANCA_UNICA" class="enable" no-caps>Contratar</u-btn>
              <u-btn v-else class="enable" no-caps>Habilitar</u-btn>
              <u-btn flat class="m-l-xs b-radius-3px" no-caps>Definições de preço</u-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="services loading m-t-xxl" v-else>
        <div class="flex column center items-center justify-center">
          <sl-loading class="size-18" content="" />
          <div class="m-t">Aguarde enquanto buscamos os produtos disponíveis para você...</div>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import {UInput} from "uloc-vue"
import {list} from "@/domain/marketplace/services/produtos"
import SlLoading from "components/layout/components/Loading.vue"
import * as types from "@/domain/marketplace/helpers/types"

export default {
  name: "MarketplaceDashboard",
  data () {
    return {
      search: null,
      loading: true,
      produtos: []
    }
  },
  components: {
    SlLoading,
    UInput
  },
  computed: {
    types () {
      return types
    },
    filteredProdutos () {
      if (!this.search) {
        return this.produtos
      }
      return this.produtos.filter(p => {
        return p.nome.toLowerCase().includes(this.search.toLowerCase()) ||
            p.descricaoSimples.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load () {
      const filtros = []
      filtros.push('sortBy=order')
      filtros.push('descending=false')
      this.loading = true
      list(1000, 1, filtros.join('&'), true)
          .then(response => {
            this.produtos = response.data.result
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    open (produto) {
      this.$router.push({name: 'marketplace.produto', params: {id: produto.id}})
    }
  }
}
</script>
